import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import pslogo from "../images/pslogo.png";
import cedlogo from "../images/ced.jpg";
import Avatar from "@mui/material/Avatar";

const Projects = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  let cardCSS = {
    display: "flex",
    borderRadius: "8px",
    background: "#E9F9FF",
    color: "#130f49",
    flexDirection: "column",
  };
  let textCss = { flex: 1, width: "auto", height: 350, fontSize: "24px" };
  let typoGarphyCss = {
    flex: 1,
    width: "100%",
    height: "auto",
    fontSize: "24px",
    display: "flex",
    flexDirection: "column",
  };
  let imageCss = { width: 200 };
  if (window.matchMedia("(max-width: 767px)").matches) {
    cardCSS = {
      display: "flex",
      borderRadius: "8px",
      background: "#E9F9FF",
      color: "#130f49",
      width: "100%",
      flexDirection: "column",
      minWidth: "355px",
      minHeight: "414px",
    };
    textCss = { display: "flex", height: "100%", fontSize: "13px" };
    typoGarphyCss = { flex: 1, fontSize: "16px", display: "flex" };
    imageCss = { width: "100%" };
  }
  return (
    <>
      <main className="main_block_first">
        <div className="container">
          <div>
            <h1 style={{color:"white",padding:"15px 0"}}>Projects</h1>
          </div>
        </div>
      </main>
      <main
        className="main_block_second "
        style={{
          flexDirection: "column",
          display: "flex",
          paddingBottom: "10px",
        }}
      >
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: "40px",
          }}
        >
          <Card sx={cardCSS}>
            <CardHeader
              title="Myfonts.com Site Consolidation"
              subheader="April, 2023 - Current"
            />
            <CardContent sx={textCss}>
              <Typography
                variant="body2"
                style={{ flexDirection: "column" }}
                color=""
                sx={typoGarphyCss}
              >
                <div className="paragraph">
                  {" "}
                  I worked on implementing internationalization, localization,
                  and pay by invoice features while updating typography.
                  Additionally, In this project i am also the key person to
                  successfully migrate two chlid websites into the parent
                  website and user data to Auth0.
                </div>

                <p className="subHeading">Key Project Work:</p>

                <ol className="project_point_list">
                  <li>Internationalization</li>
                  <li>Localization</li>
                  <li>Pay By Invoice Feature</li>
                  <li>Typography Update</li>
                  <li>Migration of Two Websites</li>
                  <li>Migration of User Data to Auth0</li>
                </ol>
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: "40px",
          }}
        >
          <Card sx={cardCSS}>
            <CardHeader
              title="Development of New Myfonts.com"
              subheader="December 2021 - March 2023"
            />
            <CardContent sx={textCss}>
              <Typography variant="body2" color="" sx={typoGarphyCss} style={{ flexDirection: "column" }}>
                <div class="paragraph">
                  <a className="project_link" target="_blank" rel="noopener noreferrer" href={"//www.myfonts.com"}>Myfonts.com</a> is an e-commerce
                  website where user purchase fonts as digital products
                </div>
                <p className="subHeading">Stack Used</p>
                <div class="paragraph">
                  React for headless components, Html, Css, JavaScript, Node.js,
                  Express.js, MongoDB, Shopfy, Algolia, Auth0
                </div>
                <p className="subHeading">Key backend services developed inExpress.js:</p>
                <ol className="project_point_list">
                  <li>Discount Pricing Service </li>
                  <li>Font Download Service</li>
                  <li>IP Address Location Service</li>
                  <li>PDF Download Service  </li>
                  <li>Promotions Service</li>
                  <li>Bundles Service</li>
                  <li>Product Ranking Service</li>
                </ol>
           
             </Typography>
            </CardContent>
          </Card>
          
        </div>
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
        >
          <Card sx={cardCSS}>
            <CardHeader
              title="CedCommerce Google Feed"
              subheader="January 2020 - December 2021"
            />
            <CardContent sx={textCss}>
              <Typography variant="body2" color="" sx={typoGarphyCss} style={{ flexDirection: "column" }}>
                <div class="paragraph">
                  <a className="project_link" target="_blank" rel="noopener noreferrer" href={"https://apps.shopify.com/google-express-integration"}>CedCommerce Google Feed</a> is an app to manage feed for multiple Google programs, Sync products to Google, Manage unlimited Google orders.
                </div>
                <p className="subHeading">Stack Used</p>
                <div class="paragraph">
                React, Shopify Polaris, Phalcon, Node.js, MongoDB, MySQL, AWS, GraphQl APIs, REST APIs.  
                </div>
           
             </Typography>
            </CardContent>
          </Card>
          
        </div>
      </main>
    </>
  );
};

export default Projects;
