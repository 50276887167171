import {
    createBrowserRouter,
  } from "react-router-dom";
import About from "../pages/about.js";
import WorkExperience from "../pages/experience.js";
import Projects from "../pages/project.js";
import Certificates from "../pages/certificates.js";

const router  =  createBrowserRouter([
    {
      path: "/",
      element: <About/>
    },
    {
      path: "/work-experience",
      element: <WorkExperience/>
    },
    {
      path: "/projects",
      element: < Projects/>
    },
    {
      path: "/certificates",
      element: < Certificates/>
    },
  ]);
export  default router 