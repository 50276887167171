import React from 'react'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";


const CertificationCard = ({id,path,name}) => {

  let cardWidth ={width:"350px"} 
  if (window.matchMedia("(max-width: 767px)").matches) {
  cardWidth = {width:"350px"}
  }
  return (
    <Card sx={cardWidth} >


      <CardMedia
        component="img"
        image={path}
        alt={name}
      />
         <CardContent >
        <Typography
          variant="body2"
          color=""
        >
          <b style={{textDecoration:"none"}}>{name}</b>
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CertificationCard