import React, { useContext } from "react";
import style from "./css/navigation.module.css";
import logo from "../images/logo.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";
import MobileMenu from "./mobileMenu";
import MobileNavigationContext from "../store/mobileNavigationContext.js";
const Navigation = () => {
  const openCta = useContext(MobileNavigationContext);

  return (
    <div className={style.navigation}>
      <FontAwesomeIcon
        className={style.mobileButton}
        onClick={openCta.handleMenuToggle}
        icon={faBarsStaggered}
      />
      <div className={style.logo}>
        Anuj Shukla {"   "}
        {"   "}
        {"   "}
        <FontAwesomeIcon icon={faLaptopCode} />
      </div>
      {<MobileMenu />}
    </div>
  );
};

export default Navigation;
