import React, { useState } from "react";
import style from "./css/header.module.css";
import Navigation from "./naviagtion";
import ResumeButton from "./resumeButton";
import Menu from "./menu";

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleNavItems = () => {
    setShowNav(!showNav);
  };
  return (
    <sticky-header class="header-wrapper header-wrapper--border-bottom">
      <div className={`container ${style.header}`}>
        <Navigation />
        <Menu />
        <ResumeButton />
        
      </div>
    </sticky-header>
  );
};

export default Header;
