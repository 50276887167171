import { createContext, useState } from "react";
const MobileNavigationContext = createContext({
  menuOpen: [],
  handleMenuToggle: () => {},
  closeCta: () => {},
});

export function MobileNavigationContextProvider(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    console.log("hiiiiiii")
    setMenuOpen(!menuOpen);
  };
  const closeCta = () => {
    setMenuOpen((previousState) => {
        return false;
      });
  };
  const context = {
    menuOpen: menuOpen,
    handleMenuToggle,
    closeCta
  };

  return (
    <MobileNavigationContext.Provider value={context}>
      {props.children}
    </MobileNavigationContext.Provider>
  );
}

export default MobileNavigationContext;
