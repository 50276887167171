import React, { useContext } from "react";
import style from "./css/mobileMenu.module.css";
import data from "../data/data.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MobileNavigationContext from "../store/mobileNavigationContext.js";
import { Link } from 'react-router-dom';

const MobileMenu = () => {
  const openCta = useContext(MobileNavigationContext);
  const getMenu = () => {
    return data?.data?.header?.menu.map((lineItem, index) => (
      <li key={index}>
       <Link onClick={openCta.closeCta} to={lineItem.url}>{lineItem.name}</Link>
      </li>
    ));
  };
  const menuClass = openCta.menuOpen
    ? `${style.mobileMenu}  ${style.open}`
    : `${style.mobileMenu}  ${style.shut}`;

    const maskClass = openCta.menuOpen
    ? `${style.mask}  ${style.maskOpen}`
    : `${style.mask}  ${style.maskShut}`;

  return (
    <nav style={{display:"flex",flexDirection:"row"}} >
      <div className={menuClass} >
            <FontAwesomeIcon
              className={style.close}
              onClick={openCta.handleMenuToggle}
              icon={faXmark}
            />
            <ul className={style.menu}>{getMenu()}</ul>
        </div>
        <div className={maskClass} >

        </div>
    </nav>
  );
};

export default MobileMenu;
