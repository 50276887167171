import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./pages/about.js";
import WorkExperience from "./pages/experience.js";
import Projects from "./pages/project.js";
import Certificates from "./pages/certificates.js";
function App() {
  return (
      <Routes >
        <Route exact path="/" element={<About />} />
        <Route path="/work-experience" element={<WorkExperience />} />
        <Route path="/projects" element= {<Projects/>} />
        <Route path="/certificates" element={<Certificates />} />
      </Routes>
     
  );
}

export default App;
