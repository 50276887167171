import React from "react";
import CertificationCard from "../components/certificationCard";
import Data from "../data/data.json";
console.log(Data.data);

const Certificates = () => {
  const certificatesCard = () => {
    return Data.data.certifications.data.map((certificate) => {
      console.log(certificate);
      return (
        <div className="gridItemCertificate">
          <a style={{textDecoration:"none"}}
            key={certificate.id}
            href={certificate.image_url} // Set the 'href' attribute to the image source
            target="_blank" // Open the link in a new tab
            rel="noopener noreferrer" // Recommended for security reasons
          >
            <CertificationCard
              id={certificate.id}
              path={certificate.image_url}
              name={certificate.name}
            />
          </a>
        </div>
      );
    });
  };
  const cardData = certificatesCard();
  return (
    <>
      <main className="main_block_first">
        <div className="container">
            <h1 style={{color:"white",padding:"15px 0"}}>Certifications</h1>
          </div>
      </main>
      <main className="main_block_second">
        
          <div className="container">
            <div className="gridCertificate">{cardData}</div>
          </div>
      </main>
    </>
  );
};
export default Certificates;
