import React from "react";
import Header from "./header.js";
import Footer from "./footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  boxSizing: "borderBox",
};

const Layout = (props) => {
  return (
    <>
      {" "}
      <Router>
        <Header />
        {props.children}
        <Footer />
      </Router>
    </>
  );
};

export default Layout;
