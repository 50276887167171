
import React from 'react'

const TechLogo = ({id,path,name}) => {
  return (
    <div  style={{display:"flex",flexDirection:"column"}}>
        <div>
        <img src={path} width="100" height="50" />
        </div>
        
    </div>
  )
}

export default TechLogo