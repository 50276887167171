import React from 'react'
import data from "../data/data.json";
import style from "./css/navigation.module.css";
import { Link } from 'react-router-dom';
const getMenu = () => {
    const menu = data?.data?.header?.menu.map((lineItems, index) => {
      console.log(lineItems);

      return (
        <li key={index}>
         <Link to={lineItems.url}>{lineItems.name}</Link>
        </li>
      );
    });
    return menu;
  };

function menu() {
  return (
    <nav className={style.inner_navigation}>
    <ul className={style.menue}>{getMenu()}</ul>
  </nav>
  )
}

export default menu