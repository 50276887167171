import React from "react";
import style from "./css/resumeButton.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import { saveAs } from 'file-saver';



const ResumeButton = () => {
    const downloadFile = () => {
      const filePath = '/AnujShukla.pdf'; // Replace with the actual file path

      // Use the file-saver library to trigger the download
      saveAs(filePath, 'AnujShukla.pdf');
    }

  return (
    <button onClick={downloadFile} className={style.resumeDownload}><FontAwesomeIcon icon={faDownload} /> Resume</button>
  )
}

export default ResumeButton;
