import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

const YourCardComponent = ({ imagePath, text1, text2 }) => {
  let cardCSS = {
    display: "flex",
    borderRadius: "8px",
    background: "#E9F9FF",
    color: "#130f49",
  };
  let textCss= { flex: 1, width: "100%", height: 350, fontSize: "24px" };
  let typoGarphyCss = { flex: 1, width: "100%", height: 350, fontSize: "24px" };
  let imageCss = { width: 200 };
  if(window.matchMedia("(max-width: 767px)").matches) {
     cardCSS = {
      display: "flex",
      borderRadius: "8px",
      background: "#E9F9FF",
      color: "#130f49",
      width:"100%",
      flexDirection:"column",
      minWidth: "355px",
      minHeight:"414px"
      
    };
    textCss= { display:'flex', height: "100%", fontSize: "13px"};
    typoGarphyCss = { flex: 1, height: 440, fontSize: "16px" };
    imageCss = { width: "100%" };
  }
  return (
    <Card sx={cardCSS}>
      <CardContent sx={textCss}>
        <Typography
          variant="body2"
          color=""
          sx={ typoGarphyCss}
        >
          <p>{text1}</p>
          <p>{text2}</p>
        </Typography>
      </CardContent>

      <CardMedia
        component="img"
        sx={imageCss} // Adjust image width as needed
        image={imagePath}
        alt="anuj Shukla Image"
      />
    </Card>
  );
};

export default YourCardComponent;
