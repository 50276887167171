import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import pslogo from "../images/pslogo.png";
import cedlogo from "../images/ced.jpg";
import Avatar from "@mui/material/Avatar";

const Experiance = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  let cardCSS = {
    display: "flex",
    borderRadius: "8px",
    background: "#E9F9FF",
    color: "#130f49",
    minWidth: "1348px",
    flexDirection: "column",
  };
  let textCss = { flex: 1, width: "auto", height: 350, fontSize: "24px" };
  let typoGarphyCss = {
    flex: 1,
    width: "100%",
    height: "auto",
    fontSize: "24px",
    display: "flex",
    flexDirection: "column",
  };
  let imageCss = { width: 200 };
  if (window.matchMedia("(max-width: 767px)").matches) {
    console.log("hiiiii")
    cardCSS = {
      display: "flex",
      borderRadius: "8px",
      background: "#E9F9FF",
      color: "#130f49",
      width: "100%",
      flexDirection: "column",
      minWidth: "355px",
      minHeight: "414px",
    };
    textCss = { display: "flex", height: "100%", fontSize: "13px",flexDirection: "column" };
    typoGarphyCss = { flex: 1, height: 440, fontSize: "16px", display: "flex" };
    imageCss = { width: "100%" };
  }
  return (
    <>
      <main className="main_block_first">
        <div className="container">
          <div>
            <h1 style={{color:"white",padding:"15px 0"}}>Work Experience</h1>
          </div>
        </div>
      </main>
      <main
        className="main_block_second "
        style={{
          flexDirection: "column",
          display: "flex",
          paddingBottom: "10px",
        }}
      >
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
          style={{
            flexDirection: "column",
            display: "flex",
            paddingBottom: "40px",
          }}
        >
          <Card sx={cardCSS}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={pslogo}
                  aria-label="company logo "
                >
                  R
                </Avatar>
              }
              title="Publicis Sapient"
              subheader="December 08, 2021 - Current"
            />
            <CardContent sx={textCss}>
              <Typography variant="body2" style={{flexDirection:"column"}} color="" sx={typoGarphyCss}>
                {" "}
                <div className="paragraph">
                  During my tenure at the company, I played a pivotal role in
                  the dynamic evolution of www.myfonts.com. As a dedicated team
                  member, I seamlessly navigated between frontend and backend
                  responsibilities, contributing to diverse facets of this
                  transformative project.
                </div>
                <div className="paragraph">
                  Aiming for excellence, I undertook the challenge of
                  refactoring existing code, effectively enhancing the
                  platform's performance and user experience. My expertise in
                  React was put to good use as I crafted and implemented
                  innovative React components, breathing new life into the user
                  interface of www.myfonts.com.
                </div>
                <div className="paragraph">
                  On the backend front, I showcased my proficiency by developing
                  a custom Express.js-based service application. This strategic
                  addition infused the website with tailor-made functionalities,
                  enriching its capabilities and setting it apart in the
                  competitive landscape.
                </div>
                <div className="paragraph">
                  Beyond technical contributions, I took on the role of a
                  mentor, providing guidance and knowledge to new team members
                  entering the project. My passion for the project and its
                  technologies was infectious, and I took pride in fostering a
                  collaborative and growth-oriented environment.
                </div>
                <div className="paragraph">
                  My journey with www.myfonts.com was marked by innovation,
                  collaboration, and a commitment to excellence. I'm honored to
                  have been a driving force behind its transformation and am
                  excited about the impact it continues to make in the digital
                  realm.
                </div>
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
        >
          <Card sx={cardCSS}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={cedlogo}
                  aria-label="company logo "
                >
                  R
                </Avatar>
              }
              title="CedCommerce"
              subheader="January 27, 2020 - December 08, 2021"
            />
            <CardContent sx={textCss}>
              <Typography  style={{flexDirection:"column"}} variant="body2" color="" sx={typoGarphyCss}>
                <div className="paragraph">
                  During my tenure at CedCommerce, I assumed a pivotal role in
                  steering the success of the Google Feed Application—a
                  testament to my proficiency and commitment in delivering
                  excellence.
                </div>
                <div className="paragraph">
                  A core facet of my contribution involved the development of
                  both front-end and back-end components, with React and
                  Express.js serving as the bedrock of these endeavors. This
                  meticulous approach ensured that our application not only
                  showcased a visually appealing and user-friendly interface but
                  also operated seamlessly under the hood.
                </div>
                <div className="paragraph">
                  A notable accomplishment during this tenure was the seamless
                  migration of the application architecture from the traditional
                  Class-Based Component model to the more contemporary and
                  efficient React Hooks paradigm. This transition not only
                  revitalized the codebase but also optimized development
                  workflows, fostering a more streamlined and maintainable code
                  ecosystem.
                </div>
                <div className="paragraph">
                  In addition to architectural enhancements, I led the
                  initiative in scripting and executing data migration processes
                  to MongoDB. This responsibility demanded precision and
                  diligence, and I took every measure to ensure a smooth
                  transition of critical data elements while upholding data
                  integrity.
                </div>
                <div className="paragraph">
                  My engagement with CedCommerce epitomized a dedication to
                  innovative problem-solving, technical prowess, and the ability
                  to drive transformative changes. The cumulative effect of my
                  efforts leaves an indelible mark on the Google Feed
                  Application and resonates with the company's commitment to
                  technical excellence.
                </div>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Experiance;
