import React, { useEffect, useState } from "react";
import image from "../images/self.jpg";
import styles from "../pages/about.module.css";
import YourCardComponent from "../components/card.js";
import Data from "../data/data.json";
import TechLogo from "../components/TechLogo.js";

const About = () => {

  const textList = [
    "optimist",
    "traveller",
    "superhero movies fan",
    "bookworm",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const text1 = ` Greetings, I'm Anuj Shukla, working as a Experience Engineer L2 at Publicis Sapient, a tech virtuoso with a passion for
  creating digital marvels. My expertise revolves around the
  enchanting realms of React, Node.js, JavaScript, Express.js,
  MongoDB, and AWS.`;
  const text2 = ` Whether I'm embarking on a solo coding adventure
  or collaborating within a dynamic team, I bring a unique blend of 
  proficiency and innovation to the table. With a magician's touch, I
  transform intricate challenges into seamless solutions, crafting
  software that leaves an indelible mark. Join me on this captivating
  journey as we navigate the intricate tapestry of technology, turning
  ideas into impactful realities.`;

  const [displayText1, setDisplayText1] = useState("");
  const [charIndex1, setCharIndex1] = useState(0);
  const [displayText2, setDisplayText2] = useState("");
  const [charIndex2, setCharIndex2] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length);
        setFade(false);
      }, 1000);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (charIndex1 < text1.length) {
      setTimeout(() => {
        setDisplayText1(text1.slice(0, charIndex1 + 1));
        setCharIndex1(charIndex1 + 1);
      }, 25);
    }
    if (charIndex1 === text1.length && charIndex2 < text2.length) {
      setTimeout(() => {
        setDisplayText2(text2.slice(0, charIndex2 + 1));
        setCharIndex2(charIndex2 + 1);
      }, 25);
    }

    const timer = setTimeout(() => {
      setShowImage(true);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [charIndex1, text1, charIndex2, text2]);

  const techStackData = () => {
    return Data.data.techStack.data.map((tech) => {
      return (
        <div  title ={tech.name}className="gridItem">
        
            <TechLogo
              id={tech.id}
              path={tech.image_url}
              name={tech.name}
            />
      
        </div>
      );
    });
  };

  return (
    <>
      <main className="main_block_first">
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}} className="container">
          <div className="innerHeaderOne"  style={{width:"100%"}}>
            <h1 style={{color:"white",padding:"100px 0"}}>
              Hi, I'm a Full Stack Developer and <br></br>
              <span
                style={{ display: "inline-flex",padding:"15px 0" }}
                className={`changing-text ${fade ? "fade-out" : ""}`}
              >
                {textList[currentIndex]}.
              </span>
            </h1>
          </div>
          <div className="innerHeaderTwo" style={{width:"100%",display:"flex",flexFlow:"row-reverse"}} >
            <img className = "headerImage" src="/images/headerImage.webp" />
          </div>
        </div>
      </main>
      <main className="main_block_second">
        <div
          className={` container slide-in-card ${isVisible ? "visible" : ""}`}
        >
          <YourCardComponent
            imagePath={image}
            text1={displayText1}
            text2={displayText2}
            showImage={showImage}
          />
        </div>
      </main>
      <main className="main_block_third" style={{background:" #161636",padding:"50px 0"}}>
      <div className="container">   
            <h1 style={{color:"white"}}>
              My Tech Stack! 
            </h1>
            <div className="technologyWrapper"  style={{display:"flex",flexWrap: "wrap"}}>
           { techStackData()}
            </div>
        </div>
      </main>
    </>
  );
};

export default About;
